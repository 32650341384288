import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import parse from "html-react-parser"

import Navbar from "../components/Navbar/Navbar"
import Footer from "../components/Footer/Footer"
import Seo from "../components/Seo"

const InternalSeminars = ({ data: { allWpSeminar } }) => {
  const internalSeminars = allWpSeminar.nodes

  return (
    <React.Fragment>
      <Seo title={"Internal Seminars"} />
      <Navbar />
      <main className="marginTopNavbar">
        <div className="main-container">
          <section className="internal-seminars">
            <h1 className="internal-seminars__title">Internal Seminars</h1>

            {internalSeminars.map((seminar, seminarIndex) => {
              const title = seminar.SeminarGQL.title
              const link = seminar.SeminarGQL.link
              const image =
                seminar.SeminarGQL.thumbnailImage.localFile.childImageSharp
                  .gatsbyImageData
              const date = seminar.SeminarGQL.date
              const description = seminar.SeminarGQL.description

              return (
                <div className="seminar-card" key={seminarIndex}>
                  <div>
                    <GatsbyImage
                      draggable="false"
                      className="seminar-card__image"
                      alt={title}
                      image={image}
                    />
                  </div>

                  <div>
                    <h2 className="seminar-card__title">{title}</h2>
                    <p className=" seminar-card__date small-text">
                      <time>{date}</time>
                    </p>
                    <p className="seminar-card__description">
                      {parse(description)}
                    </p>

                    <a
                      className="seminar-card__link"
                      href={link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View More ↗
                    </a>
                  </div>
                </div>
              )
            })}
          </section>
        </div>
      </main>
      <Footer />
    </React.Fragment>
  )
}

export default InternalSeminars

export const internalSeminars = graphql`
  {
    allWpSeminar(sort: { fields: date, order: DESC }) {
      nodes {
        SeminarGQL {
          title
          link
          description
          date
          thumbnailImage {
            localFile {
              childImageSharp {
                gatsbyImageData(aspectRatio: 1.43)
              }
            }
          }
        }
      }
    }
  }
`
